import './App.css';
import Minter from './Minter'

function App() {
  return (
    <div className="App">
      <div class="page-width banner-photo">
        <div class="promo-image-parent">
          <img src="logo.png" loading="lazy" alt="" class="promo-image"></img>
            
          </div>
          
      </div>

      <div class="page-width main-content wf-section">
        <div class="redirect-section">
        <Minter></Minter>
        </div>
      </div>
      <div class="footer wf-section">
        <div class="footer-main-content">
          <p class="powered-by-text">Powered by Top Drawer Merch</p>
        </div>
        
      </div>
    </div>
  );
}

export default App;
